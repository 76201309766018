<script setup lang="ts">
import { usePageStore } from '~/stores/page'

const pageStore = usePageStore()
const { pageName } = storeToRefs(pageStore)
</script>

<template>
  <v-expansion-panels v-model="pageName" variant="accordion" flat>
    <v-expansion-panel value="tests-new">
      <v-expansion-panel-title class="pa-2 bg-accent"> Creating a Test </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>Click <strong>NEW</strong> from the Tests section of the homepage.</p>
              <img src="~/assets/images/docs/tests_creating_001.png" />
            </li>
            <li>
              <p>Assign a <strong>Test Title</strong>, <strong>Category</strong>, and <strong>Tags</strong>. Then click <strong>Save</strong>.</p>
              <p class="text-red">*Categories and Tags are not required. However, they are beneficial for organization as well as advanced searches.</p>
              <img src="~/assets/images/docs/tests_creating_002.png" />
            </li>
            <li>
              <p>Once the Test has been created, a <strong>Notes/Instructions</strong> section will be added at the top. Here, the instructor can add directives that will be added to the PDF and RTF files when exported or printed.</p>
              <img src="~/assets/images/docs/tests_creating_003.png" />
            </li>
            <li>
              <p>To add new questions to the Test, hover the mouse over <strong>NEW</strong> to view the question type options. From the dropdown, choose <strong>True/False</strong>, <strong>Multiple Choice</strong>, or <strong>Multiple Response</strong>. Choose a question type from the menu to be redirected to the Question Editor and to create the new question.</p>
              <p class="text-red">*The question type can be changed/updated once in the Question Editor.</p>
              <img src="~/assets/images/docs/tests_creating_004.png" />
            </li>
            <li>
              <p>To add questions from an existing Question Bank, click <strong>SELECT</strong>. The Question Selector will allow you to select random or specific questions from published Question Banks that you are subscribed to.</p>
              <img src="~/assets/images/docs/tests_creating_005.png" />
            </li>
            <li>
              <p>Once the test has been finalized, <strong>Print</strong> or <strong>Export</strong> the test.</p>
              <p class="text-red">*Tests can be exported as PDF, RTF, or Moodle XML.</p>
              <img src="~/assets/images/docs/tests_creating_006.png" />
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="exporting">
      <v-expansion-panel-title class="pa-2 bg-accent"> Exporting a Test </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>To export a test, locate the desired test within the Test Manager and click <strong>Edit</strong> (<strong>pencil icon</strong>) or <strong>View</strong> (<strong>eyeball icon</strong>).</p>
              <img src="~/assets/images/docs/tests_exporting_001.png" />
            </li>
            <li>
              <p>Hover over the <strong>Export</strong> icon to show the dropdown menu. From the menu, choose to export as a <strong>PDF</strong>, <strong>RTF</strong>, or <strong>Moodle XML</strong>.</p>
              <img src="~/assets/images/docs/tests_exporting_002.png" />
            </li>
            <li>
              <p>When exporting as a <strong>PDF</strong> or <strong>RTF</strong>, the setting options include updating the <strong>Filename</strong>, adjusting the <strong>Settings</strong>, and viewing how the test will look.</p>
              <ol style="list-style-type: lower-alpha">
                <li>Settings by default are all selected.</li>
                <ol style="list-style-type: lower-roman">
                  <li><strong>Name/Date</strong>: Unchecking will remove the line for the student to write their name and date.</li>
                  <li><strong>Header</strong>: Unchecking will remove the test title from the test.</li>
                  <li><strong>Footer</strong>: Unchecking will remove the page numbers (if the test is more than one page).</li>
                  <li><strong>Answer Key</strong>: Unchecking will remove the answer key, rationale, and reference from the bottom of the test. Unchecking the answer key is ideal if the test is be used for students.</li>
                </ol>
              </ol>
            </li>
            <li>
              <p>Once all settings have been confirmed, click <strong>EXPORT</strong> at the top or bottom of the page. The PDF or RTF will be added to the computer's download folder.</p>
              <img src="~/assets/images/docs/tests_exporting_003.png" />
            </li>
            <li>
              <p>When exporting as XML, the setting options include updating the <strong>Filename</strong> and adding a <strong>Question name prefix</strong>.</p>
              <p class="text-red">*The XML file type will be used to import the test into the IEC CMS.</p>
              <img src="~/assets/images/docs/tests_exporting_004.png" />
            </li>
            <li>
              <p>The <strong>Question name prefix</strong> is a mandatory field. You will not be able to EXPORT until this field is completed.</p>
              <p></p>
              <p>Using a similar, yet shortened, name that is related to the filename is recommended. For example, if the test's filename is "First Semester Final," the prefix would be similar to "1st Sem Final." This will help to easily identify questions in the CMS.</p>
              <p class="text-red">*The maximum number of characters allowed in the <strong>Question name prefix</strong> is 15.</p>
              <img src="~/assets/images/docs/tests_exporting_005.png" />
            </li>
            <li>
              <p>Once all settings have been confirmed, click <strong>EXPORT</strong> at the top or bottom of the page. The XML file will be added to the computer's download folder.</p>
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="tests">
      <v-expansion-panel-title class="pa-2 bg-accent"> Managing Tests </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <p class="bg-white text-subtitle-2">Tests contain specific organizations of questions and can be exported into the IEC CMS or printed. Within Tests, you can create, edit, export, or print tests.</p>
          <ol>
            <li>
              <p>Click <strong>MANAGE</strong> from the Tests section of the homepage to view the existing Tests.</p>
              <p><img src="~/assets/images/docs/tests_managing_001.png" /></p>
              <p><img src="~/assets/images/docs/tests_managing_002.png" /></p>
            </li>
            <li>
              <p>Create a new Test by clicking <strong>NEW TEST</strong> at the top of the page.</p>
              <p><img src="~/assets/images/docs/tests_managing_003.png" /></p>
            </li>
            <li>
              <p>Tests are listed in alphabetical order but can also be searched for by a specific Test Title or Filtered by Categories and Test Tags.</p>
              <p><img src="~/assets/images/docs/tests_managing_004.png" /></p>
            </li>
            <li>
              <p>Click the <strong>arrow</strong> to the right of the test <strong>ACTIONS column</strong> to view when the Test was <strong>Created</strong> and <strong>Last Modified</strong>.</p>
              <p><img src="~/assets/images/docs/tests_managing_005.png" /></p>
            </li>
            <li>
              <p>Additional <strong>ACTIONS</strong> include editing the test (<strong>pencil icon</strong>), viewing the completed test (<strong>eyeball icon</strong>), duplicating the test (<strong>layered paper icon</strong>), and deleting the test (<strong>trash can icon</strong>).</p>
              <p><img src="~/assets/images/docs/tests_managing_006.png" /></p>
            </li>
            <li>
              <p>Navigate through the Tests by using the arrows at the bottom of the screen or adjusting the <strong>Items per page</strong> dropdown menu.</p>
              <p><img src="~/assets/images/docs/tests_managing_007.png" /></p>
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="tests-id-edit">
      <v-expansion-panel-title class="pa-2 bg-accent"> Printing a Test </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>To print a test, locate the desired test within the Test Manager and click <strong>Edit</strong> (<strong>pencil icon</strong>) or <strong>View</strong> (<strong>eyeball icon</strong>).</p>
              <img src="~/assets/images/docs/tests_printing_001.png" />
            </li>
            <li>
              <p>Click <strong>Print</strong> (<strong>Printer icon</strong>) to view the print settings.</p>
              <img src="~/assets/images/docs/tests_printing_002.png" />
            </li>
            <li>
              <p>The printer settings give the option to update the <strong>Filename</strong>, adjust the test layout (<strong>Settings</strong>), and to view how the test will look.</p>
              <ol style="list-style-type: lower-alpha">
                <li>Settings by default are all selected.</li>
                <ol style="list-style-type: lower-roman">
                  <li><strong>Name/Date</strong>: Unchecking will remove the lines for the student to write their name and date.</li>
                  <li><strong>Header</strong>: Unchecking will remove the test title from the test.</li>
                  <li><strong>Footer</strong>: Unchecking will remove the page numbers (if the test is more than one page).</li>
                  <li><strong>Answer Key</strong>: Unchecking will remove the answer key, rationale, and reference from the bottom of the test. Unchecking the answer key is ideal if the test will be used for students.</li>
                </ol>
              </ol>
            </li>
            <li>
              <p>Once all settings have been confirmed, click <strong>PRINT</strong> at the top or bottom of the page.</p>
              <img src="~/assets/images/docs/tests_printing_003.png" />
            </li>
            <li>
              <p>At this point, you will be prompted with your local printer options. Review these settings and <strong>Print</strong>.</p>
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="editor">
      <v-expansion-panel-title class="pa-2 bg-accent"> Question Editor </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>When editing a Question Bank or Test, locate the question that needs to be modified and click <strong>Edit question</strong> (<strong>pencil icon</strong>) to open the Question Editor.</p>
              <p><img src="~/assets/images/docs/editor_001.png" /></p>
              <img src="~/assets/images/docs/editor_002.png" />
            </li>
            <li>
              <p>Modify the question text. Add images, tables, and/or narratives in the <strong>Question Text</strong> textbox.</p>
              <img src="~/assets/images/docs/editor_003.png" />
            </li>
            <li>
              <p>Change the <strong>Question Type</strong>, select an <strong>Answer</strong>, choose the number of <strong>Columns</strong>. Columns indicate how the answers will display when printed or exported to PDF or RTF. By default, four columns will be selected.</p>
              <img src="~/assets/images/docs/editor_004.png" />
            </li>
            <li>
              <p>You can also modify, delete, or add additional responses under <strong>Responses</strong>.</p>
              <p class="text-red">*It is important to delete any unused responses so they do not show in the finished product.</p>
              <img src="~/assets/images/docs/editor_005.png" />
            </li>
            <li>
              <p>Modify the <strong>Question Info</strong> by adding Tags, Difficulty level, Code Reference, Feedback, Internal Notes, and more.</p>
              <img src="~/assets/images/docs/editor_006.png" />
            </li>
            <li>
              <p>View when the question was <strong>Created</strong> and <strong>Last Modified</strong> by scrolling to the bottom of the page.</p>
              <img src="~/assets/images/docs/editor_007.png" />
            </li>
            <li>
              <p>After the question has been edited, click <strong>SAVE</strong> at the top or bottom of the page to be directed back to the Question Bank/Test.</p>
              <img src="~/assets/images/docs/editor_008.png" />
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="tests-id-edit-select">
      <v-expansion-panel-title class="pa-2 bg-accent"> Question Selector </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <p class="bg-white text-subtitle-2">The Question Selector is used when creating/editing Tests. The Question Selector allows the user to pull questions from Question Banks they are subscribed to and create custom Tests.</p>
          <ol>
            <li>
              <p>When creating/editing a test, click <strong>SELECT</strong>.</p>
              <img src="~/assets/images/docs/tests_selecting_001.png" />
            </li>
            <li>
              <p>Then, select the Question Bank(s) to pull questions from and click <strong>NEXT</strong> at the bottom of the page.</p>
              <img src="~/assets/images/docs/tests_selecting_002.png" />
            </li>
            <li>
              <p>With the Question Bank(s) selected, choose <strong>SELECT RANDOMLY</strong> or <strong>SELECT FROM A LIST</strong>.</p>
              <img src="~/assets/images/docs/tests_selecting_003.png" />
            </li>
            <li>
              <p>By default, <strong>SELECT RANDOMLY</strong> is chosen. Choose how many questions should be pulled from the combined Question Banks and then click <strong>SUBMIT</strong>.</p>
              <img src="~/assets/images/docs/tests_selecting_004.png" />
            </li>
            <li>
              <p><strong>SELECT FROM A LIST</strong> allows you to view each question from the selected Question Bank and choose exactly which questions should be in the test.</p>
              <img src="~/assets/images/docs/tests_selecting_005.png" />
            </li>
            <li>
              <p>Next, choose the Question Bank to pull questions from by clicking the dropdown menu.</p>
              <img src="~/assets/images/docs/tests_selecting_006.png" />
            </li>
            <li>
              <p>When the desired Question Bank has been selected, place a checkmark with each question to be added. Once all the questions have been selected, click <strong>SUBMIT</strong>.</p>
              <p class="text-red">*Switch between Question Banks to choose questions from each. When submitted, selected questions from all Question Banks will be added.</p>
              <img src="~/assets/images/docs/tests_selecting_007.png" />
            </li>
            <li>
              <p>The new questions will appear at the bottom of the test. Using the mouse, click, hold, and then drag the questions into the preferred order.</p>
              <img src="~/assets/images/docs/tests_selecting_008.png" />
            </li>
            <li>
              <p>Click <strong>SAVE</strong> at the top of the page once all questions have been added and rearranged.</p>
              <img src="~/assets/images/docs/tests_selecting_009.png" />
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped></style>
