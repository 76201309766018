<script setup lang="ts">
import { usePageStore } from '~/stores/page'

const pageStore = usePageStore()
const { pageName } = storeToRefs(pageStore)
</script>

<template>
  <div class="pa-2 pt-1 bg-white text-body-2 docs-text">
    <ol>
      <li>
        <p>Access <strong>Categories</strong> from the left menu.</p>
        <img src="~/assets/images/docs/categories_001.png" />
      </li>
      <li>
        <p>To create a category, click <strong>NEW CATEGORY</strong>.</p>
        <img src="~/assets/images/docs/categories_002.png" />
      </li>
      <li>
        <p>Enter a <strong>Category Title</strong> and click <strong>Save</strong>.</p>
        <img src="~/assets/images/docs/categories_003.png" />
      </li>
      <li>
        <p>To search for a category, type the title of the category into the <strong>search box</strong>.</p>
        <img src="~/assets/images/docs/categories_004.png" />
      </li>
      <li>
        <p>Filter categories by chapter by clicking the <strong>Filter by Chapter</strong> dropdown menu and selecting the desired chapter(s).</p>
        <img src="~/assets/images/docs/categories_005.png" />
      </li>
      <li>
        <p>To change/update the category name, click <strong>Edit</strong> under the ACTIONS column.</p>
        <p class="text-red">*Categories cannot be deleted once they have been created.</p>
        <img src="~/assets/images/docs/categories_006.png" />
      </li>
      <li>
        <p>Once the name has been changed/updated, click <strong>Save</strong>. The save button will only be enabled if changes have been applied.</p>
        <img src="~/assets/images/docs/categories_007.png" />
      </li>
      <li>
        <p>Once the category has been created, add it to a Question Bank or Test. This setting can be found at the top of both the Question Bank and Test edit page.</p>
        <img src="~/assets/images/docs/categories_008.png" />
      </li>
      <li>
        <p>When managing Question Banks and Tests, quickly identify the category by locating the <strong>Category</strong> column.</p>
        <img src="~/assets/images/docs/categories_009.png" />
      </li>
      <li>
        <p>Additionally, when managing Question Banks and Tests, you can also <strong>Filter by Category</strong>.</p>
        <img src="~/assets/images/docs/categories_010.png" />
      </li>
    </ol>
  </div>
</template>

<style scoped></style>
