<script setup lang="ts">
import { usePageStore } from '~/stores/page'

const pageStore = usePageStore()
const { pageName } = storeToRefs(pageStore)
</script>

<template>
  <div class="pa-2 pt-1 bg-white text-body-2 docs-text">
    <p>Narratives are blocks of content that can be inserted into one or more questions. Narratives can be created through the Question Bank Manager and the Question Editor but can only be added to a question through the Question Editor.</p>
    <ol>
      <li>
        <p>To create a narrative through Question Banks, click <strong>MANAGE</strong> under Question Banks on the homepage.</p>
        <p><img src="~/assets/images/docs/qb_managing_001.png" /></p>
      </li>
      <li>
        <p>Locate the desired Question Bank and click <strong>Narrative</strong> (<strong>paper with plus sign icon</strong>).</p>
        <p><img src="~/assets/images/docs/qb_narratives_001.png" /></p>
      </li>
      <li>
        <p>Search for existing narratives or create a <strong>NEW NARRATIVE</strong>.</p>
        <p><img src="~/assets/images/docs/qb_narratives_002.png" /></p>
      </li>
      <li>
        <p>To create a new narrative, enter a <strong>Narrative Title</strong>, and add the <strong>Narrative Content</strong>. In the Narrative Content textbox, you can add text, images, and tables. Once the narrative is complete, click <strong>Save</strong>.</p>
        <p><img src="~/assets/images/docs/qb_narratives_003.png" /></p>
      </li>
      <li>
        <p>Additionally, existing narratives can be <strong>EDITED</strong> and <strong>DELETED</strong> from this page.</p>
        <p><img src="~/assets/images/docs/qb_narratives_004.png" /></p>
      </li>
      <li>
        <p>Use the Question Editor to create or add a narrative to a specific question. Locate the desired Question Bank or Test and click <strong>Edit</strong> (<strong>Pencil Icon</strong>).</p>
        <p><img src="~/assets/images/docs/qb_narratives_005.png" /></p>
      </li>
      <li>
        <p>Locate the question and click <strong>Edit Question</strong>. This will open the Question Editor.</p>
        <p><img src="~/assets/images/docs/qb_narratives_006.png" /></p>
      </li>
      <li>
        <p>In the Question’s <strong>Text Editor</strong>, click <strong>+NARRATIVE</strong>.</p>
        <p><img src="~/assets/images/docs/qb_narratives_007.png" /></p>
      </li>
      <li>
        <p>Create a <strong>NEW NARRATIVE</strong> or select an existing narrative from the list by clicking <strong>INSERT</strong> next to the preferred narrative.</p>
        <p><img src="~/assets/images/docs/qb_narratives_008.png" /></p>
      </li>
      <li>
        <p>The narrative will be added above the Question Text. <strong>Edit</strong> or <strong>Delete</strong> the narrative by clicking the icons in the top right corner.</p>
        <p><img src="~/assets/images/docs/qb_narratives_009.png" /></p>
      </li>
    </ol>
  </div>
</template>

<style scoped></style>
