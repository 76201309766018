<script setup lang="ts">
import { usePageStore } from '~/stores/page'

const pageStore = usePageStore()
const { rootPage } = storeToRefs(pageStore)

const route = useRoute()
</script>

<template>
  <v-container fluid class="">
    <!-- HEADER -->
    <v-expansion-panels v-model="rootPage" variant="accordion">
      <v-expansion-panel value="question-banks" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Question Banks </v-expansion-panel-title>
        <v-expansion-panel-text>
          <HelpQuestionBanks />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="tests" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Tests </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2">
          <HelpTests />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="categories" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Categories </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2">
          <HelpCategories />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="narratives" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Narratives </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2">
          <HelpNarratives />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="tags" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Tags </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2">
          <HelpTags />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="chapters" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Chapters </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2 bg-white"> Coming soon </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="reports" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Reports </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2 bg-white"> Coming soon </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="notifications" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Notifications </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2 bg-white"> Coming soon </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="users" bg-color="brand-blue-grey">
        <v-expansion-panel-title class="pa-2"> Users </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-2 bg-white"> Coming soon </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<style scoped>
:deep(.v-expansion-panel-title) {
  min-height: 40px;
}
:deep(.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static)) {
  min-height: 48px;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0px 0px 0px 8px;
}
</style>
