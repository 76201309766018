<script setup lang="ts">
import { usePageStore } from '~/stores/page'

const pageStore = usePageStore()
const { pageName } = storeToRefs(pageStore)
</script>

<template>
  <div class="pa-2 pt-1 bg-white text-body-2 docs-text">
    <p>Tags allow questions to be associated with a topic, code section, concept, or other metadata. Tags can be created for individual questions (Question Tags) or for entire tests (Test Tags). Question Tags can be created through the Question Editor, and Test Tags can be created when editing a Test. View a list of all Tags through the menu on the left.</p>
    <ol>
      <li>
        <p>Access <strong>Tags</strong> from the left menu.</p>
        <p><img src="~/assets/images/docs/tags_001.png" /></p>
      </li>
      <li>
        <p>To create a Question Tag, click <strong>NEW QUESTION TAG</strong>.</p>
        <p><img src="~/assets/images/docs/tags_002.png" /></p>
      </li>
      <li>
        <p>Enter the <strong>New Question Tag</strong> name and click <strong>Save</strong>.</p>
        <p><img src="~/assets/images/docs/tags_003.png" /></p>
      </li>
      <li>
        <p>To create a Test Tag, scroll down and click <strong>NEW TEST TAG</strong>. Enter a Test Tag name and click <strong>Save</strong>.</p>
        <p><img src="~/assets/images/docs/tags_004.png" /></p>
      </li>
      <li>
        <p>Use the <strong>Search</strong> tool to locate specific Question Tags and Test Tags.</p>
        <p class="text-red">*Make sure to use the search tool that corresponds with the tag type you are searching for.</p>
        <p><img src="~/assets/images/docs/tags_005.png" /></p>
      </li>
      <li>
        <p><strong>Question Tags</strong> can be created/added to individual questions in Question Banks and Tests through the <strong>Question Editor</strong> under the <strong>Question Info</strong> section.</p>
        <p><img src="~/assets/images/docs/tags_006.png" /></p>
      </li>
      <li>
        <p>When editing a Question Bank, you can also bulk add or remove a Question Tag by selecting one, some, or all questions within the bank, selecting a tag in the search box, and clicking <strong>APPLY TAG</strong> or <strong>REMOVE TAG</strong>.</p>
        <p><img src="~/assets/images/docs/tags_007.png" /></p>
      </li>
      <li>
        <p><strong>Test Tags</strong> can also be created/added when building new Tests or managing existing Tests.</p>
        <p><img src="~/assets/images/docs/tags_008.png" /></p>
      </li>
      <li>
        <p>Filter Question Banks and Tests by Tag through the <strong>Filter by Tag</strong> feature when editing.</p>
        <p><img src="~/assets/images/docs/tags_009.png" /></p>
      </li>
    </ol>
  </div>
</template>

<style scoped></style>
