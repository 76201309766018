<script setup lang="ts">
import { usePageStore } from '~/stores/page'

const pageStore = usePageStore()
const { pageName } = storeToRefs(pageStore)
</script>

<template>
  <v-expansion-panels v-model="pageName" variant="accordion" flat>
    <v-expansion-panel value="question-banks-new">
      <v-expansion-panel-title class="pa-2 bg-accent"> Creating a Question Bank </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>Click <strong>NEW</strong> from the Question Banks section of the homepage.</p>
              <img src="~/assets/images/docs/qb_creating_001.png" />
            </li>
            <li>
              <p>Enter the <strong>Title</strong>, <strong>Subtitle</strong>, and <strong>Category</strong>. Once the Title has been filled in, you will be able to click <strong>SAVE</strong>.</p>
              <span class="text-red">*Categories are not required but are beneficial for the organization of Question Banks and advanced searches. Categories can also be added/updated at a future time if the desired category is not listed.</span><br />
              <img src="~/assets/images/docs/qb_creating_002.png" alt="save" />
            </li>
            <li>
              <p>From the Edit Question Bank screen, create a <strong>NEW</strong> question or <strong>IMPORT</strong> questions.</p>
              <span class="text-red">*By default, the questions within a Question Bank will be in alphabetical order.</span><br />
              <img src="~/assets/images/docs/qb_creating_003.png" />
            </li>
            <li>
              <p>When all questions have been added, click <strong>SAVE</strong>.</p>
              <img src="~/assets/images/docs/qb_creating_004.png" />
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="question-banks-id-edit">
      <v-expansion-panel-title class="pa-2 bg-accent"> Editing a Question Bank </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>Click <strong>MANAGE</strong> from the Question Banks section of the homepage.</p>
              <img src="~/assets/images/docs/qb_editing_001.png" />
            </li>
            <li>
              <p>Locate the Question Bank and click Edit (<strong>pencil icon</strong>).</p>
              <img src="~/assets/images/docs/qb_editing_002.png" alt="import questions" />
            </li>
            <li>
              <p>At the top of the page, edit the <strong>Question Bank Title</strong>, <strong>Question Bank Subtitle</strong>, <strong>Category</strong>, or toggle the <strong>Publish</strong> setting of the Question Bank.</p>
              <img src="~/assets/images/docs/qb_editing_003.png" alt="Select File" />
            </li>
            <li>
              <p>Once a Question Bank has been published, it will become visible to anyone who is subscribed to that linked category. If a category is not linked, the published Question Bank will only be visible to users with similar roles.</p>
              <img src="~/assets/images/docs/qb_editing_004a.png" alt="Select File" />
            </li>
            <li>
              <p><strong>Search</strong> for a specific question topic, <strong>Filter by Question Type</strong> (True/False, Multiple Choice, or Multiple Response), or <strong>Filter by Tag</strong>. Click the <strong>magnifying glass</strong> to the right of the search box to show <strong>Advanced Searches</strong>.</p>
              <img src="~/assets/images/docs/qb_editing_004.png" alt="Select File" />
            </li>
            <li>
              <p>To the right of the search options, hover the mouse over <strong>NEW</strong> to view the question type options. From the dropdown menu, choose <strong>True/False</strong>, <strong>Multiple Choice</strong>, or <strong>Multiple Response</strong>. Choose a question type from the menu to be redirected to the Question Editor and to create the new question.</p>
              <p class="text-red">*The question type can be changed/updated once in the Question Editor.</p>
              <img src="~/assets/images/docs/qb_editing_005.png" alt="save" />
            </li>
            <li>
              <p>Click <strong>IMPORT</strong> to upload an existing Question Bank file. Compatible files include .docx, rtf, xml (Moodle), and .zip (Blackboard 7.1).</p>
              <img src="~/assets/images/docs/qb_editing_006.png" alt="save" />
            </li>
            <li>
              <p>Click <strong>RECYCLE BIN</strong> to view questions that have been deleted from the Question Bank. Options to restore one, some, or all deleted questions exist within the Recycle Bin.</p>
              <img src="~/assets/images/docs/qb_editing_007.png" alt="save" />
            </li>
            <li>
              <p>Utilize the selection tool to enable the <strong>DELETE</strong> button or to <strong>APPLY</strong> or <strong>REMOVE Question TAGs</strong>.</p>
              <img src="~/assets/images/docs/qb_editing_009a.png" alt="save" />
            </li>
            <li>
              <p>If a Question Tag is applied to a question, the tag will show just above the question text as well as in the Question Editor.</p>
              <img src="~/assets/images/docs/qb_editing_010a.png" alt="save" />
            </li>
            <li>
              <p>To open the Question Editor for an individual question, click <strong>Edit Question (pencil icon)</strong>. Modify the question text, add images, tables, or narratives in the <strong>Question Text</strong> box, alter the <strong>Responses</strong>, or add <strong>Question Info</strong>.</p>
              <img src="~/assets/images/docs/qb_editing_011.png" alt="save" />
            </li>
            <li>
              <p>New questions must have the <strong>Question Text</strong> and <strong>Responses</strong> filled out, as well as an <strong>Answer</strong> selected in order to <strong>Save</strong> the question.</p>
              <img src="~/assets/images/docs/qb_editing_012a.png" alt="save" />
            </li>
            <li>
              <p>Clicking the <strong>Duplicate (paper icon)</strong> will open a copy of the question in Question Editor. From here, modify and save the questions as a new Question Bank.</p>
              <span class="text-red">*By default, the copied Question Bank will take the name of the original Question Bank and add the word "Copy" to the end of the title. This title can be changed when editing.</span><br />
              <img src="~/assets/images/docs/qb_editing_010.png" alt="save" />
            </li>
            <li>
              <p>Click <strong>Send Errata (bell icon)</strong> to enter and submit Errata Notes if the question or content within the question appears to be inaccurate.</p>
              <img src="~/assets/images/docs/qb_editing_014a.png" alt="save" />
            </li>
            <li>
              <p>Click <strong>History (clock icon)</strong> to view all changes that have been made to the question.</p>
              <img src="~/assets/images/docs/qb_editing_012.png" alt="save" />
            </li>
            <li>
              <p>Click <strong>Delete (trash can icon)</strong> to remove the question from the Question Bank. Restore deleted questions by clicking the <strong>Recycle Bin</strong>.</p>
              <img src="~/assets/images/docs/qb_editing_013.png" alt="save" />
            </li>
            <li>
              <p>Click <strong>Show more (down arrow)</strong> to see the <strong>Reference</strong>, <strong>Feedback</strong>, <strong>Internal Notes</strong>, etc., that are attached to the question.</p>
              <img src="~/assets/images/docs/qb_editing_014.png" alt="save" />
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="importing">
      <v-expansion-panel-title class="pa-2 bg-accent"> Importing a Question Bank </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
          <ol>
            <li>
              <p>Click <strong>NEW</strong> to create a new Question Bank or <strong>MANAGE</strong> to edit an existing Question Bank.</p>
              <img src="~/assets/images/docs/qb_importing_001.png" />
            </li>
            <li>
              <p>If going through the Manager, locate the desired Question Bank and click <strong>Edit</strong> (<strong>pencil icon</strong>).</p>
              <img src="~/assets/images/docs/qb_importing_002a.png" alt="import questions" />
            </li>
            <li>
              <p>Once on the edit Question Bank page, click <strong>IMPORT</strong> to select a file and add questions to the Question Bank.</p>
              <img src="~/assets/images/docs/qb_importing_002.png" alt="import questions" />
            </li>
            <li>
              <p>To import, drag and drop the file into the box or click <strong>Select File</strong> to open the File Explorer.</p>
              <span class="text-red">*Compatible files include docx, rtf, xml (Moodle), and zip (Blackboard 7.1).</span><br />
            </li>
            <li>
              <p>Once the file has been added, click <strong>IMPORT</strong>.</p>
              <img src="~/assets/images/docs/qb_importing_003.png" alt="Select File" />
            </li>
            <li>
              <p>Questions will be imported in alphabetical order. </p>
              <img src="~/assets/images/docs/qb_importing_006.png" alt="save" />
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel value="question-banks">
      <v-expansion-panel-title class="pa-2 bg-accent"> Managing Question Banks </v-expansion-panel-title>
      <v-expansion-panel-text class="pa-2">
        <div class="text-body-2 docs-text">
         <p>Question Banks contain libraries of questions associated with specific topics. Question Banks are grouped by category. Within Question Banks, you can add, edit, or import questions.</p>
          <ol>
            <li>
              <p>Click <strong>MANAGE</strong> from the Question Banks section of the homepage to view the existing Question Banks.</p>
              <p><img src="~/assets/images/docs/qb_managing_001.png" /></p>
              <p><img src="~/assets/images/docs/qb_managing_002a.png" /></p>
            </li>
            <li>
              <p>Create a new Question Bank by clicking <strong>NEW QUESTION BANK</strong> at the top of the page.</p>
              <p><img src="~/assets/images/docs/qb_managing_003.png" /></p>
            </li>
            <li>
              <p>Question banks are listed in alphabetical order but can also be searched for using a specific Question Bank title. They can also be filtered by Category or Question Tag.</p>
              <p><img src="~/assets/images/docs/qb_managing_004.png" /></p>
            </li>
            <li>
              <p>Click the <strong>arrow</strong> to the right of the Question Bank <strong>ACTIONS column</strong> to view when the Question Bank was <strong>Created</strong> and <strong>Last Modified</strong>.</p>
              <p><img src="~/assets/images/docs/qb_managing_005.png" /></p>
            </li>
            <li>
              <p>Additional <strong>ACTIONS</strong> include editing the Question Bank (<strong>pencil icon</strong>), viewing the completed Question Bank (<strong>eyeball icon</strong>), viewing the narratives attached to the Question Bank (<strong>paper with plus sign icon</strong>), and deleting the Question Bank (<strong>trash can icon</strong>).</p>
              <p><img src="~/assets/images/docs/qb_managing_006.png" /></p>
            </li>
            <li>
              <p>Navigate through the Question Banks by using the arrows at the bottom of the screen or adjusting the <strong>Items per page</strong> dropdown.</p>
              <p><img src="~/assets/images/docs/qb_managing_007.png" /></p>
            </li>
          </ol>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style scoped></style>
